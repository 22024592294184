.mh-100 {height: 100%;}
@media screen and (min-width: 1216px) {
  .section.startups .startup-grid .box {
    padding: 60px 35px;
  }
}

@media screen and (min-width: 769px) {
  .section.startups .startup-grid .box {
    padding: 60px 35px;
  }
}

.section.startups .startup-grid .box {
  padding: 40px;
}

.box {
  border-radius: 12px;
  height: 100%;
}

.box {
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 0 5px 5px 0 rgba(233, 240, 243, 0.5), 0 0 0 1px #E6ECF8;
  color: #141c3a;
  display: block;
  padding: 1.25rem;
}

.section.startups .startup-grid .box .logo {
  height: 36px;
  width: auto;
}
.level img {
  display: inline-block;
  vertical-align: top;
}
@media screen and (min-width: 769px) {
.section.startups .startup-grid .box p {
    font-size: 18px;
}
}
.section.startups .startup-grid .box p {
    font-size: 16px;
}
.section p {
    margin-bottom: 2rem;
}

.section.startups .startup-grid .box a.link {
  font-weight: 400;
  font-size: 18px;
  border: none;
}

.tag:not(body).is-link.is-light {
  background-color: #ebf5ff;
  color: #0068d1;
}
.tag:not(body).is-light {
  background-color: rgba(20, 28, 58, 0.08);
  color: #141c3a;
}
.tag:not(body).is-medium {
  font-size: 1rem;
}
.tag:not(body).is-link {
  background-color: #1a8cff;
  color: #fff;
}
.tag:not(body).is-light {
  background-color: whitesmoke;
  color: rgba(0, 0, 0, 0.7);
}
.tag:not(body) {
  align-items: center;
  background-color: whitesmoke;
  border-radius: 4px;
  color: #141c3a;
  display: inline-flex;
  font-size: 0.75rem;
  height: 2em;
  justify-content: center;
  line-height: 1.5;
  padding-left: 0.75em;
  padding-right: 0.75em;
  white-space: nowrap;
}

.svg-inline--fa {
  display: var(--fa-display, inline-block);
  height: 1em;
  overflow: visible;
  vertical-align: -.125em;
}