
.badge-main-wrapper {
  width: 90%;
  max-width: 900px;
  margin: 3em auto;
  text-align: center;
}

.badge {
  position: relative;
  margin: 1.5em 3em;
  width: 4em;
  height: 6.2em;
  border-radius: 10px;
  display: inline-block;
  top: 0;
  transition: all 0.2s ease;
}
.badge:before, .badge:after {
  position: absolute;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  background: inherit;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.badge:before {
  transform: rotate(60deg);
}
.badge:after {
  transform: rotate(-60deg);
}
.badge:hover {
  top: -4px;
}
.badge .circle {
  width: 45px;
  height: 45px;
  position: absolute;
  background: #fff;
  z-index: 10;
  border-radius: 50%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.badge .circle svg  {
  font-size: 2em;
  margin-top: 8px;
}

.badge .font {
  display: inline-block;
  margin-top: 1em;
}
.badge .ribbon {
  position: absolute;
    border-radius: 4px;
    padding: 5px 5px 4px;
    width: 100px;
    z-index: 11;
    color: #fff;
    bottom: -32px;
    left: 50%;
    margin-left: -47px;
    /* height: 15px; */
    font-size: 14px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.27);
    text-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    text-transform: uppercase;
    background: linear-gradient(to bottom right, #555 0%, #333 100%);
    cursor: default;
}

.yellow {
  /* background: linear-gradient(to bottom right, #ffeb3b 0%, #fbc02d 100%); */
  color: #ffb300;
}

.orange {
  /* background: linear-gradient(to bottom right, #ffc107 0%, #f57c00 100%); */
  color: #f68401;
}

.pink {
  /* background: linear-gradient(to bottom right, #F48FB1 0%, #d81b60 100%); */
  color: #dc306f;
}

.red {
  /* background: linear-gradient(to bottom right, #f4511e 0%, #b71c1c 100%); */
  color: #c62828;
}

.purple {
  /* background: linear-gradient(to bottom right, #ab47bc 0%, #4527a0 100%); */
  color: #7127a8;
}

.teal {
  /* background: linear-gradient(to bottom right, #4DB6AC 0%, #00796B 100%); */
  color: #34a297;
}

.blue {
  /* background: linear-gradient(to bottom right, #4FC3F7 0%, #2196F3 100%); */
  color: #259af3;
}

.blue-dark {
  /* background: linear-gradient(to bottom right, #1976D2 0%, #283593 100%); */
  color: #1c68c5;
}

.green {
  /* background: linear-gradient(to bottom right, #cddc39 0%, #8bc34a 100%); */
  color: #7cb342;
}

.green-dark {
  /* background: linear-gradient(to bottom right, #4CAF50 0%, #1B5E20 100%); */
  color: #00944a;
}

.silver {
  /* background: linear-gradient(to bottom right, #E0E0E0 0%, #BDBDBD 100%); */
  color: #9e9e9e;
}

.gold {
  /* background: linear-gradient(to bottom right, #e6ce6a 0%, #b7892b 100%); */
  color: #b7892b;
}


.chip-tile {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  border: 1px solid #e4e5e7;
  border-radius: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #222325;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  font-weight: 600;
  height: 50px;
  -webkit-justify-content: center;
  justify-content: center;
  line-height: 24px;
  padding: 12px;
  text-decoration: none;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.chip-tile:hover {
  background-color: #f0e9e9;
  color: #000;
}
 .chip-tile .chip-tile-icon-wrapper {
  /* background-color: #f5f5f5;
  border-radius: 50%;
  height: 40px;
  padding: 8px;
  width: 40px;
  align-items: center;
  text-align: center; */

  display: flex;
  align-items:  center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.chip-tile-icon-wrapper img,.chip-tile-icon-wrapper svg {
  height: 40px;
  width: 40px;
}
 .chip-tile .chip-tile-text {
  margin: 0 12px;
  max-width: 172px;
  white-space: nowrap
}


.tbody-5,
.tbody-default,
.text-body-default {
  font-size: 16px;
  line-height: 24px;
}

.social-links-wrapper {
  display: flex;
  align-items: center;
}

.social-links-wrapper .icon-content {
  margin: 0 10px;
  position: relative;
}

ul {
  list-style: none;
}
.tooltip {
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  padding: 6px 10px;
  border-radius: 5px;
  opacity: 0;
  /* visibility: hidden; */
  font-size: 14px;
  transition: all 0.3s ease;
}

.icon-content:hover .tooltip {
  opacity: 1;
  visibility: visible;
  top: -50px;
}

.icon-content a {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: #4d4d4d;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
}

.icon-content a:hover {
  box-shadow: 3px 2px 45px 0px rgb(0 0 0 / 12%);
}

.icon-content a i {
  font-size: 20px;
  position: relative;
  z-index: 1;
  /* width: 30px;
  height: 30px; */
}

.icon-content a:hover {
  color: white;
}

.icon-content a .filled {
  position: absolute;
  top: auto;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: #000;
  transition: all 0.3s ease-in-out;
}

.social-links-wrapper .icon-content a:hover .filled {
  height: 100%;
}

.social-links-wrapper .icon-content a[data-social="spotify"] .filled,
.social-links-wrapper .icon-content a[data-social="spotify"]~.tooltip {
  background-color: #1db954;
}

.social-links-wrapper .icon-content a[data-social="buyMeaCoffe"] .filled,
.social-links-wrapper .icon-content a[data-social="buyMeaCoffe"]~.tooltip {
  background-color: #eecc37;
}

.social-links-wrapper .icon-content a[data-social="github"] .filled,
.social-links-wrapper .icon-content a[data-social="github"]~.tooltip {
  background-color: #383435;
}

.social-links-wrapper .icon-content a[data-social="linkedin"] .filled,
.social-links-wrapper .icon-content a[data-social="linkedin"]~.tooltip {
  background-color: #0088cc;
}

.icon-content .icon {
  text-align: center;
  display: flex;
}


@media (max-width: 992px){
  .social-links-outer  {
      display: flex;
      justify-content: center;
  }
    #hero {
      background-position: center;
    }
}